'use client';

import { ChangeEvent, useState } from 'react';
import {
	Box,
	chakra,
	Flex,
	Heading,
	HStack,
	Image,
	Input,
	Link,
	List,
	ListItem,
	Text,
	Textarea,
} from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { BsEnvelopeFill, BsTelephoneFill } from 'react-icons/bs';
import { usePostSendEmail } from '@shared/client-api';
import MaxWidthWrapper from '@/components/landing/MaxWidthWrapper';
import { errorToast, successToast } from '@/utils/toast';

import { Checkbox } from '@/components/ui/checkbox';
import { Field } from '@/components/ui/field'; // Nahrazení FormControl a FormLabel
import SolidBtn from './buttons/SolidBtn';

interface ContactButtonProps {
	linkTo: string;
	icon: IconType;
	text: string;
}

function ContactButton({ linkTo, icon: Icon, text }: ContactButtonProps) {
	return (
		<Link
			href={linkTo}
			px="24px"
			py="12px"
			display="flex"
			columnGap="12px"
			justifyContent={{ base: 'center', md: 'start' }}
			alignItems="center"
			bg="blackAlpha.50"
			border="1px solid"
			borderColor="blackAlpha.200"
			borderRadius="48px"
			color="primaryDark.100"
			_hover={{
				color: 'primaryDark.200',
				transition: '0.3s',
				textDecoration: 'none',
			}}
		>
			<Icon size="20px" color="primaryDark.100" />
			<Text color="neutralDark" border="none" transition="0.3s">
				{text}
			</Text>
		</Link>
	);
}

interface FormInputProps {
	title: string;
	placeholder: string;
	name: string;
	isRequired?: boolean;
	type?: string;
	value?: string;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

function FormInput({ title, placeholder, name, isRequired = false, type = 'text', value, onChange }: FormInputProps) {
	return (
		<Field label={title} required={isRequired}>
			<Input
				id={name}
				required={isRequired}
				type={type}
				textStyle="md"
				fontWeight="400"
				color="neutralDark"
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				px="16px"
				_placeholder={{ color: 'blackAlpha.600' }}
				_focusVisible={{ borderColor: 'primaryDark.100' }}
				bg="blackAlpha.100"
				border="1px solid"
				borderColor="gray.600"
				borderRadius="6px"
			/>
		</Field>
	);
}

export default function Contacts() {
	// const commonBgCirlceStyles = {
	// 	content: '""',
	// 	position: 'absolute',
	// 	width: '634px',
	// 	height: '634px',
	// 	borderRadius: '50%',
	// 	filter: 'blur(1000px)',
	// };

	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [message, setMessage] = useState('');
	const [agree, setAgree] = useState<boolean>(false);

	const sendEmail = usePostSendEmail();

	console.log(agree);

	return (
		<Box
			id="pricing"
			as="section"
			bg="white"
			position="relative"
			overflow="hidden"
			py={{ base: '80px', sm: '96px' }}
		>
			<Box />

			<MaxWidthWrapper>
				<Flex
					direction={{ base: 'column', lg: 'row' }}
					justifyContent="space-between"
					alignItems={{ base: 'center', lg: 'self-start' }}
					columnGap="64px"
					rowGap={{ base: '48px', sm: '64px', lg: '0' }}
				>
					<Box>
						<Flex direction="column" alignItems={{ base: 'center', lg: 'start' }} py="8px" mb="20px">
							<Image
								w={{ base: '132px', sm: '160px' }}
								h={{ base: '30px', sm: '36.95px' }}
								src="./images/taskgrip_horizontalni_svetle_gradient.png"
								alt="TaskGrip logo"
							/>

							<Heading
								as="h2"
								mt={{ base: '16px', sm: '32px' }}
								fontSize={{ base: '32px', sm: '40px' }}
								fontWeight="600"
								lineHeight="120%"
								color="neutralDark"
								fontFamily="gabarito"
								textAlign={{ base: 'center', lg: 'start' }}
							>
								Kontaktujte nás a začněte
								<Box display={{ base: 'none', sm: 'inline' }} as="span">
									<br />
								</Box>
								fungovat{' '}
								<Box as="span" color="primaryDark.100">
									online již dnes!
								</Box>
							</Heading>
						</Flex>

						<Flex direction={{ base: 'column', md: 'row' }} columnGap="24px" rowGap={4} mb="48px">
							<ContactButton
								linkTo="mailto:info@taskgrip.com"
								icon={BsEnvelopeFill}
								text="info@taskgrip.com"
							/>
							<ContactButton
								linkTo="tel:+420 775 930 155"
								icon={BsTelephoneFill}
								text="+420 775 930 155"
							/>
						</Flex>

						<Flex
							direction={{ base: 'column', md: 'row' }}
							alignItems={{ base: 'center', md: 'end' }}
							columnGap="40px"
							rowGap={8}
						>
							<Box textAlign={{ base: 'center', md: 'start' }}>
								<Text
									mb={{ base: '16px', sm: '20px' }}
									fontSize="18px"
									lineHeight="140%"
									color="neutralDark.100"
									fontWeight="600"
								>
									Informace o společnosti
								</Text>

								<List.Root
									gap={{ base: 2, sm: 3 }}
									textStyle="md"
									lineHeight="150%"
									fontWeight="400"
									color="blackAlpha.700"
									listStyle="none"
								>
									<List.Item>TaskGrip s. r. o., IČ: 21511306</List.Item>
									<List.Item>
										Spisová značka C 52788/KSHK <br /> Krajský soud v Hradci Králové
									</List.Item>
									<ListItem>Datová schránka mpyewg7</ListItem>
								</List.Root>
							</Box>

							{/* Connect links for download */}
							<Flex color="neutralLight" direction="column">
								<Link href="#!" target="_blank">
									<Image
										mb="16px"
										src="./images/download-google-play.png"
										alt="Download in Google Play"
									/>
								</Link>

								<Link href="#!" target="_blank">
									<Image src="./images/download-app-store.png" alt="Download in App Store" />
								</Link>
							</Flex>
						</Flex>
					</Box>

					<Box minW={{ base: '100%', sm: '75%', md: '458px', lg: 'auto', xl: '458px' }}>
						<form
							onSubmit={(e) => {
								e.preventDefault();
								sendEmail
									.mutateAsync({ data: { name, email, phoneNumber: phone, message } })
									.then(() => {
										setName('');
										setEmail('');
										setPhone('');
										setMessage('');
										setAgree(false);

										successToast(
											'Zpráva byla odeslána',
											'Děkujeme za vaši zprávu, brzy vám odpovíme.',
										);
									})
									.catch(() => {
										errorToast(
											'Chyba při odesílání zprávy',
											'Zkuste to prosím znovu nebo nás kontaktujte přímo na info@taskgrip.com',
										);
									});
							}}
						>
							<Flex direction="column" rowGap={6}>
								<FormInput
									title="Jméno"
									placeholder="Jan Novák"
									name="name"
									isRequired
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
								<FormInput
									type="email"
									title="E-mail"
									placeholder="vasemail@gmail.com"
									name="email"
									isRequired
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
								<FormInput
									type="tel"
									title="Telefonní číslo (volitelný)"
									placeholder="+420"
									name="phone"
									value={phone}
									onChange={(e) => setPhone(e.target.value)}
								/>

								<Field label="Vaše zpráva" required>
									<Textarea
										id="message"
										h="120px"
										placeholder="Napište vaši zprávu"
										size="md"
										px="16px"
										color="neutralDark"
										fontWeight="400"
										_placeholder={{ color: 'blackAlpha.600' }}
										_focusVisible={{ borderColor: 'primaryDark' }}
										bg="blackAlpha.200"
										border="1px solid"
										borderColor="gray.600"
										borderRadius="6px"
										required
										value={message}
										onChange={(e) => setMessage(e.target.value)}
									/>
								</Field>

								<HStack alignItems="center" gap={2}>
									<Checkbox
										id="agree"
										bg="whiteAlpha.100"
										borderRadius="2px"
										border="1px solid"
										borderColor="gray.600"
										required
										checked={agree}
										onCheckedChange={(e) => setAgree(!!e.checked)}
									/>
									<chakra.label
										htmlFor="agree"
										textStyle="md"
										fontWeight="400"
										color="neutralDark"
										cursor="pointer"
									>
										Souhlasím se zpracováním osobních údajů
									</chakra.label>
								</HStack>

								<SolidBtn
									content={
										<Flex alignItems="center" gap={2}>
											<Image src="images/checkmark.svg" w="10px" h="14px" alt="Checkmark" />{' '}
											<Text as="span">Odeslat zprávu</Text>
										</Flex>
									}
									href="#!"
									isSubmit
									isEnd
									width={{ base: '100%', sm: 'fit-content' }}
								/>
							</Flex>
						</form>
					</Box>
				</Flex>
			</MaxWidthWrapper>
		</Box>
	);
}
