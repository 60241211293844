'use client';

import React from 'react';
import { Box, Grid, Heading, Text, Flex } from '@chakra-ui/react';

import { IoSpeedometerOutline } from 'react-icons/io5';
import { BsClockHistory, BsPatchCheck, BsBarChartLine } from 'react-icons/bs';
import MaxWidthWrapper from '@/components/landing/MaxWidthWrapper';

interface FlexItemProps {
	Icon: React.ElementType;
	title: string;
	description: string;
}

function FlexItem({ Icon, title, description }: FlexItemProps) {
	return (
		<Flex
			flexDirection="column"
			textAlign={{ base: 'center', md: 'start' }}
			alignItems={{ base: 'center', md: 'self-start' }}
		>
			<Box color="primaryDark.100">
				<Icon size="32px" />
			</Box>
			<Heading
				as="h3"
				mt="16px"
				mb={{ base: '4px', sm: '8px' }}
				lineHeight="120%"
				color="neutralDark"
				fontSize={{ base: '20px', sm: '24px' }}
				fontWeight="600"
				fontFamily="gabarito"
			>
				{title}
			</Heading>
			<Text
				css={{
					textWrap: 'balance',
				}}
				maxW={{ base: '100%', sm: '294px' }}
				color="neutralDarkAlpha"
			>
				{description}
			</Text>
		</Flex>
	);
}

function Benefits() {
	return (
		<Box as="section" py="64px" bg="#F4FCF9">
			<MaxWidthWrapper>
				<Grid
					templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)', xl: 'repeat(4, 1fr)' }}
					gap={{ base: '32px', sm: '48px 32px' }}
					justifyItems="center"
				>
					<FlexItem
						Icon={BsClockHistory}
						title="Online správa"
						description="V reálném čase sledujte docházku, zakázky a finanční toky."
					/>
					<FlexItem
						Icon={BsBarChartLine}
						title="Analýza nákladů"
						description="Finanční reporty a analýzy, které vám poskytnou přehled o zakázkách."
					/>
					<FlexItem
						Icon={IoSpeedometerOutline}
						title="Rychlé a snadné použití"
						description="Intuitivní rozhraní, které zvládne každý, bez potřeby školení."
					/>
					<FlexItem
						Icon={BsPatchCheck}
						title="Cloudové řešení"
						description="Dostupnost kdykoliv a odkudkoliv,  máte vše na dosah ruky."
					/>
				</Grid>
			</MaxWidthWrapper>
		</Box>
	);
}

export default Benefits;
