import { toaster } from '@/components/ui/toaster';

export const errorToast = (title: string, description?: string, duration?: number) => {
	toaster.create({
		title,
		description,
		type: 'error',
		duration,
	});
};

export const successToast = (title: string, description?: string, duration?: number) => {
	toaster.create({
		title,
		description,
		type: 'success',
		duration,
	});
};

export const infoToast = (title: string, description?: string, duration?: number) => {
	toaster.create({
		title,
		description,
		type: 'info',
		duration,
	});
};

export const warningToast = (title: string, description?: string, duration?: number) => {
	toaster.create({
		title,
		description,
		type: 'warning',
		duration,
	});
};
