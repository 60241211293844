import { forwardRef } from 'react';
import { Checkbox as ChakraCheckbox } from '@chakra-ui/react';

export interface CheckboxProps extends ChakraCheckbox.RootProps {
	icon?: React.ReactNode;
	inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
	rootRef?: React.Ref<HTMLLabelElement>;
}

// eslint-disable-next-line react/display-name
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
	const { icon, children, inputProps, rootRef, ...rest } = props;
	return (
		<ChakraCheckbox.Root ref={rootRef} {...rest}>
			<ChakraCheckbox.HiddenInput ref={ref} {...inputProps} />
			<ChakraCheckbox.Control>{icon || <ChakraCheckbox.Indicator />}</ChakraCheckbox.Control>
			{children != null && <ChakraCheckbox.Label>{children}</ChakraCheckbox.Label>}
		</ChakraCheckbox.Root>
	);
});
