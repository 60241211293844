'use client';

import React from 'react';
import { Box } from '@chakra-ui/react';
import MaxWidthWrapper from '@/components/landing/MaxWidthWrapper';

export default function PricingTable() {
	return (
		<Box id="how-it-works" as="section" py={{ base: '80px', sm: '96px' }} bg="#F4FCF9">
			<MaxWidthWrapper>
				<script async src="https://js.stripe.com/v3/pricing-table.js" />
				{/* @ts-expect-error pricing table */}
				<stripe-pricing-table
					pricing-table-id="prctbl_1QD4SnIcP4PaROpmrvbs65oJ"
					publishable-key="pk_live_51Q4pMqIcP4PaROpmwYnDiUmjkLRIuygWQecyTsjSVPOzNtbUS07m6hcUYmO6AZygMxayoDEzqjGSHkry6k4KicwD00jafpA80s"
				/>
			</MaxWidthWrapper>
		</Box>
	);
}
