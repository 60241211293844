import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/components/landing/About.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/components/landing/Benefits.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/components/landing/CallToActionWithAnnotation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/components/landing/Contacts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/components/landing/PricingTable.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ColorModeProvider"] */ "/vercel/path0/apps/web/components/ui/color-mode.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/images/landing-bg-img.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/images/landingemailForm-img.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/react/dist/esm/components/box/box.js");
;
import(/* webpackMode: "eager", webpackExports: ["CardBody","CardDescription","CardFooter","CardHeader","CardPropsProvider","CardRoot","CardTitle"] */ "/vercel/path0/node_modules/@chakra-ui/react/dist/esm/components/card/card.js");
;
import(/* webpackMode: "eager", webpackExports: ["Flex"] */ "/vercel/path0/node_modules/@chakra-ui/react/dist/esm/components/flex/flex.js");
;
import(/* webpackMode: "eager", webpackExports: ["SimpleGrid"] */ "/vercel/path0/node_modules/@chakra-ui/react/dist/esm/components/grid/simple-grid.js");
;
import(/* webpackMode: "eager", webpackExports: ["Image"] */ "/vercel/path0/node_modules/@chakra-ui/react/dist/esm/components/image/image.js");
;
import(/* webpackMode: "eager", webpackExports: ["Input"] */ "/vercel/path0/node_modules/@chakra-ui/react/dist/esm/components/input/input.js");
;
import(/* webpackMode: "eager", webpackExports: ["Link"] */ "/vercel/path0/node_modules/@chakra-ui/react/dist/esm/components/link/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["Stack"] */ "/vercel/path0/node_modules/@chakra-ui/react/dist/esm/components/stack/stack.js");
;
import(/* webpackMode: "eager", webpackExports: ["Heading"] */ "/vercel/path0/node_modules/@chakra-ui/react/dist/esm/components/typography/heading.js");
;
import(/* webpackMode: "eager", webpackExports: ["Text"] */ "/vercel/path0/node_modules/@chakra-ui/react/dist/esm/components/typography/text.js");
