'use client';

import { Box, Heading, Text, Stack, Image, Flex, Grid, GridItem } from '@chakra-ui/react';

import MaxWidthWrapper from './MaxWidthWrapper';
import SolidBtn from './buttons/SolidBtn';
import OutlinedBtn from './buttons/OutlinedBtn';

export default function CallToActionWithAnnotation() {
	return (
		// TODO: Make backgound wrapper from this box from header and this hero, si it have share backgound

		// <Flex w="100%" alignItems="center" justifyContent="center" pt="76px">
		<Box
			as="section"
			bg="red.500"
			w="100%"
			position="relative"
			bgColor="neutralLight"
			overflow="hidden"
			// bgColor="rgb(10, 15, 13, 0.80)"
		>
			<Box
				_before={{
					content: '""',
					display: 'inline-block',
					position: 'absolute',
					top: '-34%',
					left: '0',
					w: '634px',
					h: '634px',
					bgColor: 'secondaryLight',
					opacity: '0.3',
					borderRadius: '100%',
				}}
				_after={{
					content: '""',
					display: 'inline-block',
					position: 'absolute',
					bottom: '-14%',
					right: '-14%',
					w: '634px',
					h: '634px',
					opacity: '0.7',
					bgColor: 'secondaryDark',
					borderRadius: '100%',
				}}
			/>
			<Box
				w="100%"
				pt={{ base: '96px', md: '148px', '2xl': '160px' }}
				pb={{ base: '80px', md: '140px', '2xl': '160px' }}
				backdropFilter="blur(1000px)"
			>
				<MaxWidthWrapper>
					<Grid
						gap={16}
						templateColumns={{ base: '1fr', '2xl': '1fr 700px' }}
						justifyItems={{ base: 'center', '2xl': 'unset' }}
						alignItems="center"
					>
						<GridItem>
							<Flex
								textAlign={{ base: 'center', '2xl': 'left' }}
								alignItems={{ base: 'center', '2xl': 'flex-start' }}
								direction="column"
								gap={{ base: 8, md: 10 }}
							>
								<Stack gap={{ base: 4, md: 5 }} width="100%" maxWidth="65ch">
									<Heading
										fontWeight={600}
										color="neutralDark"
										fontSize={{ base: '32px', md: '52px' }}
										lineHeight="120%"
										css={{
											textWrap: 'balance',
										}}
										as="h1"
									>
										Efektivní{' '}
										<Text as="span" color="primaryDark.100">
											správa docházky
										</Text>{' '}
										na projektech pro váš tým.
									</Heading>
									<Text
										mx="auto"
										color="green.900"
										fontSize={{ base: 'base', md: 'xl' }}
										lineHeight="150%"
										css={{ textWrap: 'balance' }}
									>
										Získejte plnou kontrolu nad docházkou. To vše s přehlednými reporty a sledováním
										nákladů v reálném čase. Ušetřete čas, peníze a mějte svůj tým pod kontrolou hned
										teď!
									</Text>
								</Stack>
								<Flex gap={6} flexWrap="wrap">
									<SolidBtn
										content={
											<Flex alignItems="center" gap={2}>
												<Image src="images/checkmark.svg" w="10px" h="14px" alt="Checkmark" />{' '}
												<Text as="span">Vyzkoušet ZDARMA</Text>
											</Flex>
										}
										width={{ base: '100%', sm: 'fit-content' }}
									/>
									<OutlinedBtn
										text="Naplánovat schůzku"
										width={{ base: '100%', sm: 'fit-content' }}
									/>
								</Flex>
							</Flex>
						</GridItem>

						<GridItem>
							<Image w="100%" src="/images/hero_section_dashboard.webp" alt="Hero image" />
						</GridItem>
					</Grid>
				</MaxWidthWrapper>
			</Box>
		</Box>
	);
}
