'use client';

import React from 'react';
import { Box, Heading, Text, Flex, Image, Grid, GridItem } from '@chakra-ui/react';
import MaxWidthWrapper from '@/components/landing/MaxWidthWrapper';

export default function About() {
	return (
		<Box id="about-us" as="section" py={{ base: '80px', sm: '96px' }} bg="#F4FCF9">
			<MaxWidthWrapper>
				<Flex flexDirection="column" rowGap="64px">
					<Grid
						templateColumns={{ base: '1fr', xl: '700px 1fr' }}
						alignItems={{ base: 'start', xl: 'center' }}
						gap={{ base: '32px', sm: '32px 96px' }}
					>
						<GridItem order={{ base: 2, xl: 1 }}>
							<Image src="./images/about-img-1.png" alt="Image" />
						</GridItem>

						<GridItem order={{ base: 1, xl: 2 }}>
							<Box maxW={{ base: '100%', md: '90%', xl: '632px' }} textAlign="start">
								<Heading
									as="h2"
									maxW={{ base: '100%', xl: '480px' }}
									fontSize={{ base: '28px', sm: '40px' }}
									fontWeight="700"
									mb={{ base: 2, sm: 4 }}
									lineHeight="120%"
									color="neutralDark"
									fontFamily="gabarito"
								>
									Zjednodušte si řízení docházky na projektech.
								</Heading>

								<Text
									maxW={{ base: '100%', md: '75%', xl: '100%' }}
									fontSize={{ base: 4, md: '18px' }}
									color="neutralDarkAlpha"
								>
									Šetřete čas a eliminujte chyby při evidenci pracovních hodin a správě projektů. Díky
									přehledným reportům budete mít vše pod kontrolou na jednom místě a získáte jasný
									obraz o tom, jak efektivně váš tým pracuje.
								</Text>
							</Box>
						</GridItem>
					</Grid>

					<Grid
						templateColumns={{ base: '1fr', xl: '1fr 700px' }}
						alignItems={{ base: 'start', xl: 'center' }}
						gap={{ base: '32px', sm: '32px 96px' }}
					>
						<GridItem order="2">
							<Image src="./images/about-img-2.png" alt="Image" />
						</GridItem>

						<GridItem order="1">
							<Box maxW={{ base: '100%', md: '90%', xl: '632px' }} textAlign="start">
								<Heading
									as="h2"
									maxW={{ base: '100%', xl: '480px' }}
									fontSize={{ base: '28px', sm: '40px' }}
									fontWeight="700"
									mb={{ base: '8px', sm: '16px' }}
									lineHeight="120%"
									color="neutralDark"
									fontFamily="gabarito"
								>
									Získejte lepší přehled o financích
								</Heading>

								<Text
									maxW={{ base: '100%', md: '75%', xl: '100%' }}
									mb={{ base: '24px', sm: '32px' }}
									fontSize={{ base: '16px', md: '18px' }}
									lineHeight="150%"
									color="neutralDarkAlpha"
								>
									Mějte své náklady a příjmy pod kontrolou díky detailním analýzám a reportům.
									Zobrazení dat vám pomůže rychle určit finanční trendy a optimalizovat rozpočet. S
									predikcí cashflow se připravíte na budoucnost a budete vždy o krok napřed.
								</Text>
							</Box>
						</GridItem>
					</Grid>

					<Grid
						templateColumns={{ base: '1fr', xl: '700px 1fr' }}
						alignItems={{ base: 'start', xl: 'center' }}
						gap={{ base: '32px', sm: '32px 96px' }}
					>
						<GridItem order={{ base: 2, xl: 1 }}>
							<Image src="./images/about-img-3.png" alt="Image" />
						</GridItem>

						<GridItem order={{ base: 1, xl: 2 }}>
							<Box maxW={{ base: '100%', md: '90%', xl: '632px' }} textAlign="start">
								<Heading
									as="h2"
									maxW={{ base: '100%', xl: '480px' }}
									fontSize={{ base: '28px', sm: '40px' }}
									fontWeight="700"
									mb={{ base: '8px', sm: '16px' }}
									lineHeight="120%"
									color="neutralDark"
									fontFamily="gabarito"
								>
									Týmová spolupráce bez hranic
								</Heading>

								<Text
									maxW={{ base: '100%', md: '75%', xl: '100%' }}
									mb={{ base: '24px', sm: '32px' }}
									fontSize={{ base: '16px', md: '18px' }}
									lineHeight="150%"
									color="neutralDarkAlpha"
								>
									Spolupracujte bez omezení, ať už jste kdekoliv. Přístup ke všem důležitým datům
									zaručuje plynulou a efektivní práci na projektech, ať už jste v kanceláři, v terénu,
									nebo u klienta. Snadné sdílení úkolů usnadní organizaci a zvýší produktivitu celého
									týmu. - UPRAVIT
								</Text>
							</Box>
						</GridItem>
					</Grid>
				</Flex>
			</MaxWidthWrapper>
		</Box>
	);
}
